<template>
  <surround-box :title="title" background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)" specialColor="#0d4257">
    <dv-scroll-board :config="config" style="width: 100%; height: 80%" />
  </surround-box>
</template>
<script>
import surroundBox from '@/components/mapComp/surroundBox4.vue'

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      title: '技术指导巡查记录统计',
      config: {},
    }
  },
  methods: {
    getList() {
      return new Promise((resolve) => {
        this.$get('technicalguidancepatrol/getPatrolStatistics').then((res) => {
          let data = res
          let config = {
            data: [],
            oddRowBGC: '',
            evenRowBGC: '',
            rowNum: 5,
          }
          data.forEach((item, index) => {
            let bottom =
              "<p style='width:100%;display:flex;justify-content:space-between;align-items:center'>" +
              '<span style="display:block;width:10%">' +
              (index + 1) +
              '</span><span style="display:block;width:80%">' +
              item.regionCodeName +
              '</span><span style="display:block;width:10%">' +
              item.patrolCount +
              '次</span></p>'
            config.data.push([
              "<div style='width:100%;display:flex;justify-content:space-between;align-items:center;padding:0 5%'>" +
                bottom +
                '</div>',
            ])
          })
          this.config = config
          resolve()
        })
      })
    },
  },
  mounted() {
    this.getList()
  },
}
</script>
<style lang='less' scoped>
.content-box {
  width: 100%;
  height: 220px;
  position: relative;
  color: #fff;
  padding: 4px 16px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .info-item {
    padding: 8px 0px;

    .info-title {
      display: flex;
      align-items: flex-start;

      span:first-child {
        padding-right: 8px;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }

    .time-status {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .no-read {
        color: #00ffdc;
      }
    }
  }
}

.chart-box {
  width: 100%;
  height: 22.6851vh;
  position: relative;

  .chart {
    width: 100%;
    height: 100%;
    margin-top: 1.5vh;
  }

  .unit {
    position: absolute;
    left: 16px;
    top: -10px;
    color: #fff;
    font-weight: bold;
  }

  .scroll-out {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    width: 22px;
    font-size: 14px;
    padding: 8px 4px;
    white-space: pre-wrap;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.7;
  }
}

.monitor-value-box {
  width: 100%;
  //display: flex;
  justify-content: center;

  align-items: center;

  .monitor-value-item {
    width: 164px;
    height: 59.2px;
    display: flex;
    align-items: center;
    border: 1px solid #00effd;
    border-radius: 4px;
    cursor: pointer;
    float: left;
    margin-right: 14px;
    margin-top: 10px;
    margin-left: 12px;
    padding: 4px;

    &:after {
      clear: both;
    }

    img {
      width: 62.4px;
      height: 62.4px;
    }

    .value {
      width: 81.6px;
      height: 62.4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .num {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .monitor-value-item-active {
    //border-color: #4dcbb9;
  }
}
</style>
